// class PluginLoader {
//   static pluginKey = null;
//   static scriptElement = null;

//   constructor(pluginKey) {
//     this.pluginKey = pluginKey;
//   }

//   loadPlugin() {
//     this.scriptElement = document.createElement("script");
//     this.scriptElement.type = "text/javascript";
//     this.scriptElement.src = `/plugins/${this.pluginKey}/${this.pluginKey}-compiled.js`;
//     this.scriptElement.async = true;
//     var element = document.getElementById(this.pluginKey);
//     if (element != null) {
//       element.style.maxHeight = "95vh";
//       element.style.overflow = "auto";
//       element.appendChild(this.scriptElement);
//     }
//     return null;
//   }

//   removePlugin() {

//     if (this.scriptElement) {
//       window.location.reload()
//     }
//     return null;
//   }
// }

// export default PluginLoader;

export default function WidgetLoader(pluginKey) {
  const element = document.getElementById(pluginKey);
  if (_.isEmpty(element) === false) {
    element.style.maxHeight = "100vh";
    element.style.overflow = "auto";

    const scriptElement = document.createElement("script");;
    scriptElement.type = "text/javascript";
    scriptElement.src = `/widgets/${pluginKey}/${pluginKey}-compiled.js`;
    scriptElement.async = true;
    element.appendChild(scriptElement);
  }
}