import { faCopyright, faCommentDots, faCalendar, faMoneyBillAlt, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faUserFriends, faLanguage, faProjectDiagram, faMapPin } from "@fortawesome/free-solid-svg-icons";

import localization from "@services/localization";

import BlogPremiumPlugin from "./br_blog_premium";
import CustomerPlugin from "./br_customer";
import EventsPremiumPlugin from "./br_event_premium";
import LocalizationPlugin from "./br_localization";
import MybrandPlugin from "./br_mybrand";
import NewsPremiumPlugin from "./br_news_premium";
import PaymentMethodsPlugin from "./br_payment_methods";
import ProcedurePlugin from "./br_procedure";
import BrandLocalizationPlugins from "./br_localization_plugins";
import PluginConnection from "./br_pluginConnection";
import BrandContentManger from "./br_poi_content_manager";
import BrandSupport from "./br_support";

const brandRoutes = [
    {
        path: "br_procedure",
        exact: true,
        name: localization.br_procedure || "Procedure",
        pluginKey: "br_procedure",
        icon: faProjectDiagram,
        element: ProcedurePlugin
    },
    {
        path: "br_payment_methods",
        exact: true,
        name: localization.br_payment_methods || "Payment methods",
        pluginKey: "br_payment_methods",
        icon: faMoneyBillAlt,
        element: PaymentMethodsPlugin
    },
    {
        path: "br_news_premium",
        exact: true,
        name: localization.br_news_premium || "News premium",
        pluginKey: "br_news_premium",
        icon: faNewspaper,
        element: NewsPremiumPlugin
    },
    {
        path: "br_localization",
        exact: true,
        name: localization.br_localization || "Localization",
        pluginKey: "br_localization",
        icon: faLanguage,
        element: LocalizationPlugin
    },
    {
        path: "br_events_premium",
        exact: true,
        name: localization.br_events_premium || "Events premium",
        pluginKey: "br_events_premium",
        icon: faCalendar,
        element: EventsPremiumPlugin
    },
    {
        path: "br_customer",
        exact: true,
        name: localization.br_customer || "Customers",
        pluginKey: "br_customer",
        icon: faUserFriends,
        element: CustomerPlugin
    },
    {
        path: "br_blog_premium",
        exact: true,
        name: localization.br_blog_premium || "Blog premium",
        pluginKey: "br_blog_premium",
        icon: faCommentDots,
        element: BlogPremiumPlugin
    },
    {
        path: "br_mybrand",
        exact: true,
        name: localization.br_mybrand || "Brand Manager",
        pluginKey: "br_mybrand",
        icon: faCopyright,
        element: MybrandPlugin
    },
    {
        path: "br_localization_plugins",
        exact: true,
        name: localization.br_localization_plugins || "Plugin localization",
        pluginKey: "br_localization_plugins",
        icon: faLanguage,
        element: BrandLocalizationPlugins
    },
    {
        path: "br_pluginConnection",
        exact: true,
        name: localization.br_pluginConnection || "Connection",
        pluginKey: "br_pluginConnection",
        icon: faCommentDots,
        element: PluginConnection
    },
    {
        path: "br_poi_content_manager",
        exact: true,
        name: localization.br_poi_content_manager || "Content manager",
        pluginKey: "br_poi_content_manager",
        icon: faMapPin,
        element: BrandContentManger
    },
    {
        path: "br_support",
        exact: true,
        name: localization.br_support || "Support",
        pluginKey: "br_support",
        icon: faMapPin,
        element: BrandSupport
    }
]
export default brandRoutes;