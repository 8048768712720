import React, { useEffect } from "react";

import WidgetLoader from "@views/widgets/WidgetLoader";

const PLUGIN_KEY = "w_room_booking";
export default function RoomBookingWidget() {
    useEffect(() => {
        WidgetLoader(PLUGIN_KEY);
    }, [])

    return (
        <div id={PLUGIN_KEY}> </div>
    );
}
