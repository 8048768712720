import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card, InputGroup } from "react-bootstrap";
import { MandatoryFieldLabel, OrbitalAddressComponentsPicker, AuthStore, OrbitalReactPhoneInput, OrbitalCancelIcon, OrbitalSaveIcon } from "orbital_common_components";
import { toast } from "react-toastify";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";

import SpecificAPI from "@services/SpecificAPI";
import localization from "@services/localization";
import * as Utils from "@services/Utils";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";
import OrbitalSelect from "@views/newCommonComponents/OrbitalSelect";

export default function OrbitalId(props) {
    const { selectedUser, hideCancelButton, onSave, onCancel } = props;
    const languageOptions = Utils.getOrbitalLanguageOptions();

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const onlyLetters = /[a-zA-Z]+/g
    const validationSchema = yup.object().shape({
        lastName: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
            .matches(onlyLetters, (localization.useOnlyLetters || "Please use only letters")),
        firstName: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
            .matches(onlyLetters, (localization.useOnlyLetters || "Please use only letters")),
        lang: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field"),
        phoneNo: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field"),
        city: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field"),
        postalCode: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
    })

    /*************************************************************************/
    /***************************** STANDARD **********************************/
    /*************************************************************************/
    useEffect(() => {
        setInitialValues(_.cloneDeep(selectedUser));
    }, [])

    /*************************************************************************/
    /***************************** FUNCTIONS *********************************/
    /*************************************************************************/
    function validateFormBeforeSave(validateForm, handleSubmit) {
        validateForm().then((errorData) => {
            if (_.isEmpty(errorData) === false) { toast.warn(localization.checkFieldsValidity || "Check fields validity") }
        })
        handleSubmit();
    }

    function saveOrbitalId(user) {
        SpecificAPI.updateMongoOrbitalId(user)
            .then((data) => {
                const orbitalIdMo = data.orbitalIdMo;

                const currentAuth = AuthStore.getAuth();
                const newAuth = { ...currentAuth, ...orbitalIdMo };
                AuthStore.setAuth(newAuth);
                sessionStorage.setItem("auth", JSON.stringify(newAuth));

                toast.success(localization.profileUpdatedSuccessfully || "Profile updated successfully");
                onSave();
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorUpdatingProfile || "Error updating profile");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/
    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setLoading(true);
                saveOrbitalId(values);
            }}
            validateOnChange={false}
            initialValues={initialValues}
            enableReinitialize={true}>
            {({ handleSubmit, handleChange, values, isValid, errors, setFieldValue, setValues, validateForm }) => (
                <LoadingOverlay active={loading} text={(localization.loading || "Loading") + "..."}>
                    <Card>
                        <Card.Header>
                            <b>{localization.profile || "Profile"}</b>
                            <span style={{ float: "right" }}>
                                <OrbitalSaveIcon tooltip={localization.save || "Save"} marginright={hideCancelButton === true ? "0px" : "15px"} onClick={() => { validateFormBeforeSave(validateForm, handleSubmit) }}></OrbitalSaveIcon>
                                {hideCancelButton !== true &&
                                    <OrbitalCancelIcon tooltip={localization.close || "Close"} onClick={() => { onCancel() }}></OrbitalCancelIcon>
                                }
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {/* <Row>
                                <Col>
                                    <textarea cols="50" rows="15" value={JSON.stringify(values, undefined, 2)} />
                                </Col>
                            </Row> */}
                            <Row className="br_mybrand_mtr">
                                <Col>
                                    <MandatoryFieldLabel halfbold={true} value={localization.lastName || "Last name"}></MandatoryFieldLabel>
                                    <InputGroup>
                                        <Form.Control
                                            name={"lastName"}
                                            disabled={_.isEmpty(values.orbitalId) === false}
                                            isInvalid={errors.lastName}
                                            placeholder={localization.lastName || "Last name"}
                                            value={values.lastName || ""}
                                            onChange={handleChange}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="margin_top_row">
                                <Col>
                                    <MandatoryFieldLabel halfbold={true} value={localization.firstName || "First name"}></MandatoryFieldLabel>
                                    <InputGroup>
                                        <Form.Control
                                            name={"firstName"}
                                            disabled={_.isEmpty(values.orbitalId) === false}
                                            isInvalid={errors.firstName}
                                            placeholder={localization.firstName || "First name"}
                                            value={values.firstName || ""}
                                            onChange={handleChange}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="margin_top_row">
                                <Col sm={4}>
                                    <MandatoryFieldLabel halfbold={true} value={localization.lang || "Lang"}></MandatoryFieldLabel>
                                    <OrbitalSelect
                                        isDisabled={_.isEmpty(values.orbitalId) === false}
                                        isInvalid={errors.lang}
                                        errorMsg={errors.lang}
                                        value={_.find(languageOptions, { "value": values.lang })}
                                        onChange={(data) => {
                                            setValues({ ...values, "lang": data.value });
                                        }}
                                        placeholder={localization.lang || "Lang"}
                                        options={languageOptions}>
                                    </OrbitalSelect>
                                </Col>
                                <Col sm={8}>
                                    <MandatoryFieldLabel halfbold={true} value={localization.phoneNo || "Phone number"}></MandatoryFieldLabel>
                                    <OrbitalReactPhoneInput
                                        isInvalid={errors.phoneNo}
                                        errorMessage={errors.phoneNo}
                                        inputProps={{
                                            name: "",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        disabled={_.isEmpty(values.orbitalId) === false}
                                        country={"it"}
                                        placeholder={localization.phoneNo || "Phone number"}
                                        enableLongNumbers={true}
                                        onChange={e => { setValues({ ...values, "phoneNo": e }); }}
                                        value={values.phoneNo || ""}
                                        enableSearchField={true}>
                                    </OrbitalReactPhoneInput>
                                </Col>
                            </Row>
                            <Row className="margin_top_row">
                                <Col>
                                    <MandatoryFieldLabel halfbold={true} value={localization.city || "City"}></MandatoryFieldLabel>
                                    {_.isEmpty(values.orbitalId) === true &&
                                        <OrbitalAddressComponentsPicker
                                            placeholder={localization.searchPlaces || "Search places"}
                                            error={errors.city}
                                            location={values.city}
                                            localization={localization}
                                            onChange={(address_components) => {
                                                const city = Utils.getCityFromAddressComponents(address_components);
                                                const country = Utils.getCountryFromAddressComponents(address_components);
                                                const postalCode = Utils.getPostalCodeFromAddressComponents(address_components);
                                                setValues({ ...values, city, country, postalCode })
                                            }}>
                                        </OrbitalAddressComponentsPicker>
                                    }
                                    {_.isEmpty(values.orbitalId) === false &&
                                        <div className="margin_bottom_row">
                                            <Form.Control
                                                disabled={_.isEmpty(values.orbitalId) === false}
                                                value={values.city || ""}>
                                            </Form.Control>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MandatoryFieldLabel halfbold={true} value={localization.country || "Country"}></MandatoryFieldLabel>
                                    <InputGroup>
                                        <Form.Control
                                            isInvalid={errors.country}
                                            placeholder={localization.country || "Country"}
                                            value={values.country || ""}
                                            disabled={true}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="margin_top_row">
                                <Col>
                                    <MandatoryFieldLabel halfbold={true} value={localization.postalCode || "Postal code"}></MandatoryFieldLabel>
                                    <InputGroup>
                                        <Form.Control
                                            name={"postalCode"}
                                            isInvalid={errors.postalCode}
                                            placeholder={localization.postalCode || "Postal code"}
                                            value={values.postalCode || ""}
                                            onChange={handleChange}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </LoadingOverlay>
            )}
        </Formik>
    )
}
