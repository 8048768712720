import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { OrbitalCancelIcon } from "orbital_common_components";

import { faTrashAlt,   faClock } from "@fortawesome/free-regular-svg-icons";
import { faDesktop,faLaptop,faMobile,faWifi,faCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

import "../../css/activeSession.css";

export default function ActiveSessions(props) {
    const {  onCancel } = props;
    
    const [sessions, setSessions] = useState([]);
   
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getActiveSessions(props.userId);
    }, []);


    
    
      const getDeviceIcon = (type) => {
        switch(type) {
          case 'mobile':
            return  <FontAwesomeIcon icon={faMobile} />;
          case 'laptop':
            return <FontAwesomeIcon icon={faLaptop} />;
          case 'desktop':
            return <FontAwesomeIcon icon={faDesktop} />;
          default:
            return <FontAwesomeIcon icon={faDesktop} />;
        }
      };

    /*************************************************************************/
    /**************************** STANDARD ***********************************/
    /*************************************************************************/
    
    const getActiveSessions = (userId) => {
        setLoading(true);
        SpecificAPI.getActiveSession(userId).then(res=>{
            var data = res.map(d=>{
                return { id: d.fingerprint, deviceName: d.deviceName, deviceType: d.devicetype, ipAddress: d.ip, lastActive: moment(d.activationtimestamp).format("YYYY-MM-DD HH:mm"), isActive: d.isenable }
            })
            setSessions(data);
            setLoading(false);
        }).catch(err=>{
            console.log(err);
            setLoading(false);
            toast.error(localization.errorgettingActiveSessions || "Error getting active sessions");
        })
    }

    const revokeActiveSession = (sessionId) => {
        setLoading(true);
        SpecificAPI.removeOrbitalIdIdentity(props.userId,sessionId).then(res=>{
            setSessions(sessions.filter(session => session.id !== sessionId));
            setLoading(false);
        }).catch(err=>{
            console.log(err);
            setLoading(false);
            toast.error(localization.errorRemovingActiveSessions || "Error removing active sessions");
        })
    }

    const revokeAllActiveSessions = (sessionId) => {
        setLoading(true);
        SpecificAPI.clearAllOrbitalIdIdentity(props.userId,sessionId).then(res=>{
            setSessions(sessions.filter(session => session.id == props.deviceFingerPrint));
            setLoading(false);
        }).catch(err=>{
            console.log(err);
            setLoading(false);
            toast.error(localization.errorRemovingActiveSessions || "Error removing active sessions");
        })
    }

    /*************************************************************************/
    /*************************** FUNCTIONS ***********************************/
    /*************************************************************************/
   

    /*************************************************************************/
    /****************************** RENDER ***********************************/
    /*************************************************************************/
   

   

    return (
        <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
            <Card>
                <Card.Header>
                    <b>{localization.activeSessions || "Active Sessions"}</b>
                    <span style={{ float: "right" }}>
                        <OrbitalCancelIcon tooltip={localization.close || "Close"} onClick={() => { onCancel() }}></OrbitalCancelIcon>
                    </span>
                </Card.Header>
                <Card.Body>
                <div className="sessionContainer">
                    <ul className="sessionList">
                        {sessions.map(session => (
                        <li key={session.id} className="sessionItem">
                            <div className="sessionDetails">
                            <div className="additionalInfo">
                                <div>{getDeviceIcon(session.deviceType)}
                                <span className="deviceName">{session.deviceName}</span></div>
                                {session.id==props.deviceFingerPrint && <div className="currentDevice">
                                    <FontAwesomeIcon className="sessionIcon" icon={faCircle} /> Current Device
                                </div>}
                            </div>
                            <div className="additionalInfo">
                                <div className="infoItem">
                                <FontAwesomeIcon className="sessionIcon" icon={faWifi} /> {session.ipAddress}
                                </div>
                                <div className="infoItem">
                                    <FontAwesomeIcon className="sessionIcon" icon={faClock} /> {session.lastActive}
                                </div>
                            </div>
                            </div>
                            
                            <FontAwesomeIcon className={session.id==props.deviceFingerPrint ? "disableRevokeIcon":"revokeIcon"} icon={faTrashAlt} onClick={() => {revokeActiveSession(session.id)}}/>
                        </li>
                        ))}
                    </ul>
                    <center><Button onClick={()=>{revokeAllActiveSessions(props.deviceFingerPrint)}} variant="danger">{localization.revokeAllActiveSessions || "Revoke All Active Sessions"}</Button></center>
                    </div>
                </Card.Body>
               
            </Card>
        </LoadingOverlay>
    )
}