import React, { useEffect } from "react";

import PluginLoader from "@views/plugins/PluginLoader";

const PLUGIN_KEY = "ow_orders";
export default function OrdersPlugin() {
    useEffect(() => {
        PluginLoader(PLUGIN_KEY);
    }, [])

    return (
        <div id={PLUGIN_KEY}> </div>
    );
}