import { faCalendarAlt, faBuilding, faCalendar, faNewspaper, faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons";
import { faUsers, faVideo, faUtensils, faUserFriends, faLanguage, faList, faMapPin } from "@fortawesome/free-solid-svg-icons";

import localization from "@services/localization";

import OwnerManagerPlugin from "./ow_attendants";
import ActivityPlugin from "./ow_activity";
import BookingPlugin from "./ow_booking";
import EventsPremiumPlugin from "./ow_events_premium";
import NewsPremiumPlugin from "./ow_news_premium";
import MatterportPlugin from "./ow_matterport";
import MatterportSubscriptionPlugin from "./ow_matterport_subscription";
import MenuPremiumPlugin from "./ow_simplemenupremium";
import CatalogPlugin from "./ow_catalog";
import CustomerPlugin from "./ow_customer";
import EventBookingPlugin from "./ow_eventBooking";
import PaymentMethodsPlugin from "./ow_payment_methods";
import MentorPlugin from "./ow_mentor";
import OwnerLocalizationPlugins from "./ow_localization_plugins";
import RoomBookingPlugin from "./ow_room_booking";
import OrdersPlugin from "./ow_orders";
import OwnerContentManagerPlugin from "./ow_poi_content_manager";
import OwnerSupport from "./ow_support";

const ownerRoutes = [
  {
    path: "ow_payment_methods",
    exact: true,
    name: localization.ow_payment_methods || "Payment methods",
    pluginKey: "ow_payment_methods",
    icon: faMoneyBillAlt,
    element: PaymentMethodsPlugin
  },
  {
    path: "ow_eventBooking",
    exact: true,
    name: localization.ow_eventBooking || "Event booking",
    pluginKey: "ow_eventBooking",
    icon: faCalendarAlt,
    element: EventBookingPlugin
  },
  {
    path: "ow_customer",
    exact: true,
    name: localization.ow_customer || "Customers",
    pluginKey: "ow_customer",
    icon: faUserFriends,
    element: CustomerPlugin
  },
  {
    path: "ow_simplemenupremium",
    exact: true,
    name: localization.ow_simplemenupremium || "Menù premium",
    pluginKey: "ow_simplemenupremium",
    icon: faUtensils,
    element: MenuPremiumPlugin
  }, {
    path: "ow_catalog",
    exact: true,
    name: localization.ow_catalog || "Catalog",
    pluginKey: "ow_catalog",
    icon: faList,
    element: CatalogPlugin
  },
  {
    path: "ow_news_premium",
    exact: true,
    name: localization.ow_news_premium || "News premium",
    pluginKey: "ow_news_premium",
    icon: faNewspaper,
    element: NewsPremiumPlugin
  },
  {
    name: localization.ow_matterspace || "MatterSpace",
    pluginKey: "ow_matterport",
    icon: faVideo,
    subRoutes: [
      {
        path: "ow_matterport",
        name: localization.ow_matterport_editor || "MatterSpace Editor",
        pluginKey: "ow_matterport",
        icon: faVideo,
        element: MatterportPlugin
      },
      {
        path: "ow_matterport_subscription",
        name: localization.ow_subscription || "Subscription",
        pluginKey: "ow_matterport",
        icon: faVideo,
        element: MatterportSubscriptionPlugin
      }
    ]
  },
  {
    path: "ow_events_premium",
    exact: true,
    name: localization.ow_events_premium || "Event premium",
    pluginKey: "ow_events_premium",
    icon: faCalendar,
    element: EventsPremiumPlugin
  },
  {
    path: "ow_attendants",
    exact: true,
    name: localization.ow_attendants || "Owner manager",
    pluginKey: "ow_attendants",
    icon: faUsers,
    element: OwnerManagerPlugin
  },
  {
    path: "ow_activity",
    exact: true,
    name: localization.ow_activity || "Activities",
    pluginKey: "ow_activity",
    icon: faBuilding,
    element: ActivityPlugin
  },
  {
    path: "ow_booking",
    exact: true,
    name: localization.ow_booking || "Booking",
    pluginKey: "ow_booking",
    icon: faCalendarAlt,
    element: BookingPlugin
  },
  {
    path: "ow_room_booking",
    exact: true,
    name: localization.ow_room_booking || "Room booking",
    pluginKey: "ow_room_booking",
    icon: faCalendarAlt,
    element: RoomBookingPlugin
  },
  {
    path: "ow_orders",
    exact: true,
    name: localization.ow_orders || "Orders",
    pluginKey: "ow_orders",
    icon: faList,
    element: OrdersPlugin
  },
  {
    path: "ow_localization_plugins",
    exact: true,
    name: localization.ow_localization_plugins || "Plugin localization",
    pluginKey: "ow_localization_plugins",
    icon: faLanguage,
    element: OwnerLocalizationPlugins
  },
  {
    name: localization.ow_mentor || "Mentor",
    pluginKey: "ow_mentor",
    icon: faCalendarAlt,
    subRoutes: [
      {
        path: "mentor",
        exact: true,
        name: localization.ow_mentor || "Mentor",
        pluginKey: "ow_mentor",
        icon: faCalendarAlt,
        element: MentorPlugin
      },
      {
        path: "mentor_purchase_history",
        exact: true,
        name: localization.ow_mentor_purchase_history || "Mentor purchase log",
        pluginKey: "ow_mentor",
        icon: faCalendarAlt,
        element: MentorPlugin
      },
      {
        path: "mentor_connection_log",
        exact: true,
        name: localization.ow_mentor_connection_log || "Mentor connection log",
        pluginKey: "ow_mentor",
        icon: faCalendarAlt,
        element: MentorPlugin
      }
    ]
  },
  {
    path: "ow_poi_content_manager",
    exact: true,
    name: localization.ow_poi_content_manager || "Content manager",
    pluginKey: "ow_poi_content_manager",
    icon: faMapPin,
    element: OwnerContentManagerPlugin
  },
  {
    path: "ow_support",
    exact: true,
    name: localization.ow_support || "Support",
    pluginKey: "ow_support",
    icon: faMapPin,
    element: OwnerSupport
}
];

export default ownerRoutes;