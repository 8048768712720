import React, { useState } from "react";
import { DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { AuthStore, OrbitalStore, SessionStorageStore, CommonUtils } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOutAlt, faCheck,faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faUser, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";

import localization from "@services/localization";

import Agreements from "@views/commonComponents/Agreements";
import ActiveSessions from "@views/commonComponents/ActiveSessions";

import { useNavigate } from "react-router-dom";

export default function SettingsAdmin() {
    const navigate = useNavigate();

    const auth = AuthStore.getAuth();
    const orbitalConfig = OrbitalStore.getOrbitalConfig();
    const containerVersion = process.env.REACT_APP_PLUGIN_VERSION;

    const [currentLang, setCurrentLang] = useState(CommonUtils.getInitialLocalizationLanguage());
    const [showAgreementsModal, setShowAgreementsModal] = useState(false);
    const [showActiveSessionModal, setShowActiveSessionModal] = useState(false);
    const [agreementType, setAgreementType] = useState(null);

    /*************************************************************************/
    /**************************** FUNCTIONS **********************************/
    /*************************************************************************/
    function setLang(lang) {
        SessionStorageStore.setCurrentLang(lang)
            .then((lang) => {
                setCurrentLang(lang);
                updateLocalizationLanguage(lang);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    function logout() {
        SessionStorageStore.removeAuth();
        SessionStorageStore.removeDashboardAuthKey();
        SessionStorageStore.removeUserAuthKey();
        SessionStorageStore.removeCurrentLang();
        sessionStorage.removeItem("pluginKey");
        AuthStore.setAuth({});
        navigate("/login/admin");
    }

    /*************************************************************************/
    /**************************** RENDER *************************************/
    /*************************************************************************/
    function parseHeader() {
        const { systemUserRole } = auth || {};
        return <center><div>{systemUserRole}</div></center>
    }

    function parseLanguages() {
        const languages = orbitalConfig.preferedlang || [];

        const languageOptions = _.map(languages, (lang, index) => {
            const label = currentLang === lang.value ? <span>{lang.value}{" "}<FontAwesomeIcon icon={faCheck} /></span> : lang.value;
            return <Dropdown.Item onClick={() => { setLang(lang.value); }} key={index} eventKey={lang.value}>{label}</Dropdown.Item>
        })
        return languageOptions;
    }

    return (
        <React.Fragment>
            <DropdownButton align="start" variant="outline-secondary" title={<FontAwesomeIcon icon={faCog} />}>
                <Dropdown.Header>{parseHeader()}</Dropdown.Header>
                <Dropdown.Item disabled={true}><FontAwesomeIcon icon={faUser} />{" "}{localization.profile || "Profile"}</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    setShowAgreementsModal(true);
                    setAgreementType("termsAndConditions");
                }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}{localization.termsAndConditions || "Terms & Conditions"}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    setShowAgreementsModal(true);
                    setAgreementType("privacyPolicy");
                }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}{localization.privacyPolicy || "Privacy Policy"}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {setShowActiveSessionModal(true)}}>
                    <FontAwesomeIcon icon={faDesktop} />{" "}{localization.activeSessions || "Active Sessions"}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>{localization.languages || "Languages"}</Dropdown.Header>
                {parseLanguages()}
                <Dropdown.Divider />
                <Dropdown.Header>{localization.versions || "Versions"}</Dropdown.Header>
                <Dropdown.Item disabled={true}><span className="versioning">{localization.container || "Container"}: {containerVersion}</span></Dropdown.Item>
                <Dropdown.Item disabled={true}><span className="versioning">{localization.plugin || "Plugin"}: {"--"}</span></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout} eventKey="4"><FontAwesomeIcon icon={faSignOutAlt} />{" "}{localization.logout || "Logout"}</Dropdown.Item>
            </DropdownButton>
            <Modal size="lg" show={showAgreementsModal}>
                <Agreements
                    agreementType={agreementType}
                    onCancel={() => { setShowAgreementsModal(false) }}>
                </Agreements>
            </Modal>
            <Modal show={showActiveSessionModal}>
                <ActiveSessions
                    userId={auth.uuid}
                    deviceFingerPrint={localStorage.getItem("o_ad_session")}
                    onCancel={() => {
                        setShowActiveSessionModal(false);
                    }} />
            </Modal>
        </React.Fragment>
    )
}